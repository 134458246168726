import {
  SHOW_GRO_LES_ADD,
  CHANGE_COMMENT,
  MAN_DATE_CHANGE,
  MAN_ADD_MEMBER,
  MAN_REMOVE_MEMBER,
  MAN_CREATE_LESSON,
  MAN_DELETE_LESSON,
  GRO_DATE_CHANGE,
  GRO_REMOVE_MEMBER,
  GRO_CREATE_LESSON,
  PRI_DATE_CHANGE,
  PRI_REMOVE_MEMBER,
  ADA_DATE_CHANGE,
  ADA_REMOVE_MEMBER,
  SHOW_LESSON_ERRORS,
  UPDATE_LESSON_ERRORS,
  LOAD_GROUP_LESSONS,
  LOAD_PRIVATE_LESSONS,
  SWITCH_LESSON_TABS,
  SHOW_FIRST_TIMERS,
  CREATE_TRIP_LESSONS,
  DELETE_LESSON,
  EDIT_LESSON,
  CHANGE_NEW_LESSON_EFFECT,
  FISRT_TIMER_NO_LESSON_WARNING
} from "../actions/action-types";
import uuid from "uuid";

const initialState = {
  comments: {
    group: "",
    private: "",
    adaptive: ""
  },
  specificInstructor: {},

  priNeedIns: "No", // private lesson need instructor
  priInsInfo: "", // private lesson instructor info
  priReq: "", // private lesson request
  adaNeedIns: "No",
  adaInsInfo: "",
  adaReq: "",
  lessonType: "", // to indicate which section you are in

  // mandatory lessons
  manLesChosen: {},

  // user selected group lessons
  groLesChosen: {},

  // user selected private lessons
  priLesChosen: {},

  // user selected adaptive lessons
  adaLesChosen: {},

  lessonValidated: false,
  lessonErrors: {},

  // Initial add more lesson button (button before adding any lessons)
  showGroLesAdd: true,
  showPriLesAdd: true,
  showAdaLesAdd: true,

  privateLessons: [],
  groupLessons: [],
  tabIndex: 0,

  // indicate whether first timers have all registered at least one lesson or not
  firstTimersWithoutLessons: {
    skiFirstTimersWithoutLesson: [],
    snowboardFirstTimersWithoutLesson: []
  },

  //this object stores the first timers ids
  showFirstTimersIds: {
    group: [],
    private: []
  },

  tripLessons:
    process.env.REACT_APP_NODE_ENV === "production"
      ? []
      : [
          {
            tripLessonId: 1,
            activity: "ski",
            lessonType: "group",
            adaptive: false,
            firstTimer: true,
            startDate: new Date("2020-02-28"),
            startTime: "09:30",
            durationHours: 3.5,
            durationDays: 4,
            flexibleDays: 0, // null if private
            instructor: "", // null if group, should be an id
            ageGroup: "child (6-14yrs)",
            minimumAge: 6,
            maximumAge: 14,
            participants: ["Alena"]
          },
          {
            tripLessonId: 2,
            activity: "snowboard",
            lessonType: "group",
            adaptive: false,
            firstTimer: false,
            startDate: new Date("2020-02-28"),
            startTime: "10:00",
            durationHours: 2.5,
            durationDays: 4,
            flexibleDays: 6,
            instructor: "", // null if group, should be an id
            ageGroup: "70yrs & over",
            minimumAge: 70,
            maximumAge: 120,
            participants: ["Carrie"]
          },
          {
            tripLessonId: 3,
            activity: "snowboard",
            lessonType: "private",
            adaptive: true,
            firstTimer: false,
            startDate: new Date("2020-02-28"),
            startTime: "13:30",
            durationHours: 3,
            durationDays: 4,
            flexibleDays: null,
            selectedInstructorOption: "2", // null if group
            instructorDetails: "Helen", // null if group
            ageGroup: "6+ yrs",
            minimumAge: 6,
            maximumAge: 120,
            participants: ["Janice"]
          },
          {
            tripLessonId: 4,
            activity: "snowboard",
            lessonType: "private",
            adaptive: false,
            firstTimer: false,
            startDate: new Date(),
            startTime: "09:00",
            durationHours: 3,
            durationDays: 1,
            flexibleDays: null, // null if private
            selectedInstructorOption: "2", // null if group
            instructorDetails: "Tommy", // null if group
            ageGroup: "6+ yrs",
            minimumAge: 6,
            maximumAge: 120,
            participants: ["James", "Mike"]
          },
          {
            tripLessonId: 5,
            activity: "ski",
            lessonType: "private",
            adaptive: true,
            firstTimer: true,
            startDate: new Date(),
            startTime: "09:00",
            durationHours: 3,
            durationDays: 1,
            flexibleDays: null, // null if private
            selectedInstructorOption: "1", // null if group
            instructorDetails: "", // null if group
            ageGroup: "3 - 5yrs",
            minimumAge: 3,
            maximumAge: 5,
            participants: ["Tony"]
          },
          {
            tripLessonId: 6,
            activity: "snowboard",
            lessonType: "private",
            adaptive: false,
            firstTimer: true,
            startDate: new Date(),
            startTime: "09:00",
            durationHours: 3,
            durationDays: 1,
            flexibleDays: null, // null if private
            selectedInstructorOption: "3",
            instructorDetails:
              "I want a female instructor who can speack French",
            ageGroup: "6+ yrs",
            minimumAge: 6,
            maximumAge: 120,
            participants: ["Steve"]
          },
          {
            tripLessonId: 7,
            activity: "ski",
            lessonType: "group",
            adaptive: false,
            firstTimer: false,
            startDate: new Date(),
            startTime: "10:00",
            durationHours: 2.5,
            durationDays: 2,
            flexibleDays: 0, // null if private
            instructor: "", // null if group, should be an id
            ageGroup: "Adult (young) (15-17yrs)",
            minimumAge: 15,
            maximumAge: 17,
            participants: ["Thor"]
          },
          {
            tripLessonId: 8,
            activity: "ski",
            lessonType: "group",
            adaptive: false,
            firstTimer: true,
            startDate: new Date(),
            startTime: "10:00",
            durationHours: 5,
            durationDays: 1,
            flexibleDays: null, // null if private
            instructor: "", // null if group, should be an id
            ageGroup: "Adult (18-64yrs)",
            minimumAge: 18,
            maximumAge: 64,
            participants: ["Bucky"]
          }
        ]
};

// Main reducer
const tripLessonReducer = (state = initialState, action) => {
  let key,
    new_lesson,
    modify_object,
    member_uuid,
    new_date,
    member_index,
    new_values;

  switch (action.type) {
    case FISRT_TIMER_NO_LESSON_WARNING: {
      return {
        ...state,
        firstTimersWithoutLessons: action.payload.firstTimersWithoutLessons
      };
    }
    case EDIT_LESSON: {
      return { ...state, tripLessons: action.payload.newTripLessons };
    }
    case DELETE_LESSON: {
      return {
        ...state,
        tripLessons: state.tripLessons.filter(
          lesson => lesson.tripLessonId !== action.payload.tripLessonId
        )
      };
    }
    // case CREATE_TRIP_LESSONS: {
    //   return {
    //     ...state,
    //     tripLessons: [action.payload.tripLessons, ...state.tripLessons]
    //   };
    // }
    case SHOW_GRO_LES_ADD:
      return {
        ...state,
        showGroLesAdd: false
      };
    case CHANGE_COMMENT:
      const new_comment = action.payload.value;
      return {
        ...state,
        comment: new_comment
      };

    case MAN_DATE_CHANGE:
      key = action.payload.key;
      new_date = action.payload.new_date;
      modify_object = state.manLesChosen[key];
      return {
        ...state,
        manLesChosen: {
          ...state.manLesChosen,
          [key]: {
            create: modify_object.create,
            act: modify_object.act,
            age: modify_object.age,
            ap: modify_object.ap,
            date: new_date,
            ppl: modify_object.ppl,
            category: modify_object.category,
            st: modify_object.st,
            dur: modify_object.dur
          }
        }
      };
    case MAN_ADD_MEMBER:
      key = action.payload.key;
      member_uuid = action.payload.memberIDs; // a list of new members
      modify_object = state.manLesChosen[key];
      return {
        ...state,
        manLesChosen: {
          ...state.manLesChosen,
          [key]: {
            create: modify_object.create,
            act: modify_object.act,
            age: modify_object.age,
            ap: modify_object.ap,
            date: modify_object.date,
            ppl: modify_object.ppl.concat(member_uuid),
            category: modify_object.category,
            st: modify_object.st,
            dur: modify_object.dur
          }
        }
      };
    case MAN_REMOVE_MEMBER:
      key = action.payload.key;
      member_uuid = action.payload.memberID;
      modify_object = state.manLesChosen[key];
      member_index = modify_object.ppl.indexOf(member_uuid);
      if (member_index >= 0) {
        return {
          ...state,
          manLesChosen: {
            ...state.manLesChosen,
            [key]: {
              create: modify_object.create,
              act: modify_object.act,
              age: modify_object.age,
              ap: modify_object.ap,
              date: modify_object.date,
              ppl: [
                ...modify_object.ppl.slice(0, member_index),
                ...modify_object.ppl.slice(member_index + 1)
              ],
              category: modify_object.category,
              st: modify_object.st,
              dur: modify_object.dur
            }
          }
        };
      } else {
        return state;
      }
    case MAN_DELETE_LESSON: {
      key = action.payload.key;
      const { [key]: value, ...remaining_lessons } = state.manLesChosen;
      return {
        ...state,
        manLesChosen: remaining_lessons
      };
    }
    // Note: this case is different from GROUP_lesson, in terms of ppl
    case MAN_CREATE_LESSON: {
      new_lesson = action.payload.new_lesson;
      const { key, act, age, date, ap, ppl, category, st, dur } = new_lesson;
      return {
        ...state,
        manLesChosen: {
          ...state.manLesChosen,
          [key]: {
            create: new Date(),
            act: act,
            age: age,
            ap: ap,
            date: date,
            ppl: ppl,
            category: category,
            st: st,
            dur: dur
          }
        }
      };
    }

    // Group Lesson Reducer
    case GRO_DATE_CHANGE:
      key = action.payload.key;
      new_date = action.payload.new_date;
      modify_object = state.groLesChosen[key];
      return {
        ...state,
        groLesChosen: {
          ...state.groLesChosen,
          [key]: {
            create: modify_object.create,
            activity: modify_object.activity,
            date: new_date,
            lessonId: modify_object.lessonId,
            age: modify_object.age,
            type: modify_object.type,
            firstTimer: modify_object.firstTimer,
            adaptive: modify_object.adaptive,
            ppl: []
          }
        }
      };
    case GRO_REMOVE_MEMBER:
      key = action.payload.key;
      member_uuid = action.payload.memberID;
      modify_object = state.groLesChosen[key];
      member_index = modify_object.ppl.indexOf(member_uuid);
      if (member_index >= 0) {
        return {
          ...state,
          groLesChosen: {
            ...state.groLesChosen,
            [key]: {
              ...modify_object,
              ppl: [
                ...modify_object.ppl.slice(0, member_index),
                ...modify_object.ppl.slice(member_index + 1)
              ]
            }
          }
        };
      } else {
        return state;
      }
    case GRO_CREATE_LESSON: {
      new_lesson = action.payload.new_lesson;
      key = new_lesson.key;
      const {
        activity,
        date,
        lessonId,
        age,
        type,
        firstTimer,
        adaptive
      } = new_lesson;
      return {
        ...state,
        groLesChosen: {
          ...state.groLesChosen,
          [key]: {
            create: new Date(),
            activity: activity,
            date: date,
            lessonId: lessonId,
            age: age,
            type: type,
            firstTimer: firstTimer,
            adaptive: adaptive,
            ppl: []
          }
        }
      };
    }
    case SWITCH_LESSON_TABS: {
      return {
        ...state,
        tabIndex: action.payload.tabIndex
      };
    }
    case PRI_DATE_CHANGE:
      key = action.payload.key;
      new_date = action.payload.new_date;
      modify_object = state.priLesChosen[key];
      return {
        ...state,
        priLesChosen: {
          ...state.priLesChosen,
          [key]: {
            create: modify_object.create,
            activity: modify_object.activity,
            date: new_date,
            lessonId: modify_object.lessonId,
            age: modify_object.age,
            type: modify_object.type,
            firstTimer: modify_object.firstTimer,
            adaptive: modify_object.adaptive,
            ppl: modify_object.ppl
          }
        }
      };
    case PRI_REMOVE_MEMBER:
      key = action.payload.key;
      member_uuid = action.payload.memberID;
      modify_object = state.priLesChosen[key];
      member_index = modify_object.ppl.indexOf(member_uuid);
      if (member_index >= 0) {
        return {
          ...state,
          priLesChosen: {
            ...state.priLesChosen,
            [key]: {
              ...modify_object,
              ppl: [
                ...modify_object.ppl.slice(0, member_index),
                ...modify_object.ppl.slice(member_index + 1)
              ]
            }
          }
        };
      } else {
        return state;
      }
    case ADA_DATE_CHANGE:
      key = action.payload.key;
      new_date = action.payload.new_date;
      modify_object = state.adaLesChosen[key];
      return {
        ...state,
        adaLesChosen: {
          ...state.adaLesChosen,
          [key]: {
            create: modify_object.create,
            activity: modify_object.activity,
            date: new_date,
            duration: modify_object.duration,
            startTime: modify_object.startTime,
            age: modify_object.age,
            maxppl: modify_object.maxppl,
            ppl: modify_object.ppl
          }
        }
      };
    case ADA_REMOVE_MEMBER:
      key = action.payload.key;
      member_uuid = action.payload.memberID;
      modify_object = state.adaLesChosen[key];
      member_index = modify_object.ppl.indexOf(member_uuid);
      if (member_index >= 0) {
        return {
          ...state,
          adaLesChosen: {
            ...state.adaLesChosen,
            [key]: {
              type: modify_object.type,
              //create: modify_object.create,
              activity: modify_object.activity,
              date: modify_object.date,
              duration: modify_object.duration,
              startTime: modify_object.startTime,
              age: modify_object.age,
              ppl: [
                ...modify_object.ppl.slice(0, member_index),
                ...modify_object.ppl.slice(member_index + 1)
              ]
            }
          }
        };
      } else {
        return state;
      }

    case SHOW_LESSON_ERRORS: {
      return {
        ...state,
        lessonValidated: action.payload.show
      };
    }
    case UPDATE_LESSON_ERRORS: {
      return {
        ...state,
        lessonErrors: {
          ...state.lessonErrors,
          ...action.payload.errors
        }
      };
    }
    case LOAD_GROUP_LESSONS: {
      return {
        ...state,
        groupLessons: action.payload.groupLessons
      };
    }
    case LOAD_PRIVATE_LESSONS: {
      return {
        ...state,
        privateLessons: action.payload.privateLessons
      };
    }
    case CHANGE_NEW_LESSON_EFFECT: {
      const updatedLesson = state.tripLessons.map(lesson => {
        if (lesson.newlesson) {
          return {
            ...lesson,
            newlesson: !lesson.newlesson
          };
        } else {
          return lesson;
        }
      });
      return {
        ...state,
        tripLessons: updatedLesson
      };
    }
    default:
      return state;
  }
};
export default tripLessonReducer;
